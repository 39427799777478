<template>
  <div class="no-print">
    <v-container 
      fluid
      class="container no-print"
    >
      <div class="container-loading" v-if="loadingRelatorio">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
      <v-main class="wrapper">
        <div class="wrapper-titulo">
          <h1 class="titulo">Relatorios</h1>
          <FiltroRangeDate
            class="mr-3 d-flex justify-center"
            @input="filtrar"
            :value="rangeDate"
            :button="true"
            :labelBtn="'Selecionar Data'"
            :actionLabels="{ apply: 'OK' }"
          />
          <div></div>
        </div>

        <div class="wrapper-subtitulo mt-5">
          <h2 class="titulo">Categorias</h2>
          <p>Mostrando 
            <menuCategorias
              :categories="formatCategories"
              :selectCategorias="selectCategorias"
              @setCategories="setCategories"
            />
          de 
            <menuContas
              :contas="accountList"
              :selectedConta="selectedConta"
              @setConta="setConta"
            />
          de
            <menuCentroCustos
              :costCenters="costCentersMapped"
              :selectedCostCenter="selectedCostCenter"
              @setCostCenter="setCostCenter"
            /> 
          </p>
        </div>

        <div class="wrapper-subtitulo">
          <p class="ml-0">Visualizado por 
            <menuTipoVisualizacao
              :tiposVisualizacoes="tiposVisualizacoes || []"
              :selectedTipoVisualizacao="selectedTipoVisualizacao"
              @setTipoVisualizacao="setTipoVisualizacao"
            />
          </p>
        </div>
        
        <v-row>
          <v-col cols="12" class="pb-0 pt-0">
            <div class="wrapper-subtitulo mb-0">
              <!-- <h2 class="titulo">Categorias</h2> -->
              <v-checkbox v-model="considerarPagos" label="Considerar movimentações não pagas"/>
              <span class="wrapper-subtitulo-total"> Total Geral: 
                {{ lancamentoPorCategorias.R.total + lancamentoPorCategorias.D.total + lancamentoCosting.D.total  | currency }}
              </span>
              
            </div>
          </v-col>
          <v-col class="text-center pt-0 mt-0" cols="12">
          </v-col>

        </v-row>

        <!-- <graficoLinha   
          :graficoDonutR="graficoDonutR"
          :graficoDonutD="graficoDonutD"
        /> -->

        <graficoPizza 
          :graficoDonutR="graficoDonutR"
          :graficoDonutD="graficoDonutD"
          :graficoColuna="graficoColuna"
          v-if="true"
        />
        
        <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
          <tableRelatorio :lancamentoPorCategorias="lancamentoPorCategorias.R" :typeCategories="'R'" class="box-table mb-3"  />

          <tableRelatorio :lancamentoPorCategorias="lancamentoCosting.D" :typeCategories="'C'" class="box-table mb-3" />
         
          <tableRelatorio :lancamentoPorCategorias="lancamentoPorCategorias.D" :typeCategories="'D'" class="box-table" />
        </v-skeleton-loader>
      </v-main>
    </v-container>

    <modalLancamento :show="modal"  />
    <modalExcluir :show="mExcluir"  />
    <modalRecibo />
  </div>
</template>

<script>
  import Events from '@/core/service/events'
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  // import { errorSnackbar } from '@/core/service/utils'
  // import apexchart from 'vue-apexcharts'
  import { cloneDeep, groupBy } from 'lodash'

  export default {
    name: 'OrganizzeFinanceiroCategorias',

    components: {
      modalLancamento: () => import('./components/modalLancamento.vue'),
      modalExcluir: () => import('./components/modalExcluir.vue'),
      modalRecibo: () => import('./components/modalRecibo'),
      FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
      tableRelatorio: () => import('./components/table'),
      menuCategorias: () => import('./components/menuCategorias'),
      menuContas: () => import('./components/menuContas'),
      menuCentroCustos: () => import('./components/menuCentroCustos'),
      // apexchart
      menuTipoVisualizacao: () => import('./components/menuTipoVisualizacao'),
      graficoPizza: () => import('./components/graficos/pizza'),
      // graficoLinha: () => import('./components/graficos/linha'),
      // graficoColuna: () => import('./components/graficos/coluna')
    },

    data: () => ({
      mExcluir: false,
      modal: false,
      mRecibo: false,
      modalDados: {},
      tiposVisualizacoes: [
        { text: 'pagamento', value: 'dtPaid'},
        { text: 'emissão', value: 'dtEmission'},
        { text: 'vencimento', value: 'dtDue'},
      ],
      skLoader: false,

      expanded:[],
      rangeDate: {
        start: '',
        end: ''
      },

      selectCategorias: [{
        id: 0,
        name: 'todas as categorias'
      }],

      selectedConta: [{
        id: 0,
        name: 'todas as contas'
      }],

      selectedCostCenter: [{
        id: 0,
        name: 'todas os centros de custos'
      }],

      selectedTipoVisualizacao: { text: 'emissão', value: 'dtEmission' },

      considerarPagos: false,
      custos: false,
      loadingRelatorio: false,

      dessertHeaders: [
        { align: 'start', text: 'Categorias', value: 'protein' },
        { align: 'start', text: 'Percentual', value: 'iron' },
        { align: 'right', text: 'Valor', value: 'teste' },
        { align: 'right', text: '', value: 'data-table-expand' },
      ],
    }),

    mounted () {
      const vm = this
      this.getCategories()
      this.getAccounts()
      this.getCostCenters()
      this.getContacts()

      this.rangeDate = {
        start: moment().format('YYYY-MM-DD'),
        end:  moment().format('YYYY-MM-DD')
      }

      Events.$on('categorias::update::tables', () => {
        console.log('veio no update')
        vm.filtrar(vm.rangeDate)
      })

     /* fecha modais */
      Events.$on('close::modal::categoria', () => {
        vm.modal = false
        vm.mExcluir = false
      })

     /* Abre modais */
      Events.$on('categorias::edit::modal', (data) => {
        let name = data.name
        if (name === 'excluir') {
          vm.mExcluir = true
          Events.$emit('modalCadastro::edit', data)
          return
        }

        if (name === 'editar') {
          vm.modal = true
          Events.$emit('modalCadastro::edit', data)
          return
        }

        vm.modal = true
        Events.$emit('modalCadastro::edit', data)
      })

      Events.$on('categorias::emitirRecibo::modal', (data) => {
        if (data.record.Contacts) {
          Events.$emit('modalCadastro::recibo', data)
        } else {
          vm.modal = true
          Events.$emit('modalCadastro::edit', data, 'recibo')
        }
      })

      this.filtrar(this.rangeDate)
    },

    watch: {
      'considerarPagos' () {
        this.filtrar(this.rangeDate)
      }
    },

    methods: {
      ...mapActions('relatorioCategorias', ['getLancamentosPorCategorias']),
      ...mapActions('organizzeFinanceiroCategorias', ['getCategories']),
      ...mapActions('organizzeFinanceiroLancamentos', ['getAccounts', 'getContacts']),
      ...mapActions('organizzeCentroCustos', ['getCostCenters']),

      filtrar (e) {
        let _addlFilter = {}
        let _filter = {}
        if (e.start !== '' && e.end !== '') {
          this.loadingRelatorio = true

          // const diffDate = moment(e.end).diff(e.start, 'months')

          // if (diffDate) {
            
          // }



          // console.log(diffDate)
          // debugger

          _addlFilter[`Records:${this.selectedTipoVisualizacao.value}_>=`] =  e.start + ' 00:00:00'
          _addlFilter[`Records:${this.selectedTipoVisualizacao.value}_<=`] =  e.end + ' 23:59:59'

          if (!this.considerarPagos) _addlFilter['Records:status_='] = 'E'
          
          _addlFilter['Records:type_<>'] = 'T'
          // _addlFilter['Records:recordCategoryId_='] = this.selectCategorias.id || undefined 
          _filter['Records:recordCategoryId_In'] = this.selectCategorias.flatMap(item => item.id).filter(item => item).join(',')|| undefined 
          // _addlFilter['Records:accountId_='] = this.selectedConta.id || undefined 
          _filter['Records:accountId_In'] = this.selectedConta.flatMap(item => item.id).filter(item => item).join(',') || undefined 
          // _addlFilter['Records:costCenterId_='] = this.selectedCostCenter.id || undefined 
          _filter['Records:costCenterId_In'] = this.selectedCostCenter.flatMap(item => item.id).filter(item => item).join(',') || undefined 
          this.getLancamentosPorCategorias({ _addlFilter, _filter })
          .finally(() => this.loadingRelatorio = false)

          this.rangeDate = {
            start: e.start,
            end: e.end
          }
        } else {
          this.loadingRelatorio = true

          this.rangeDate = {
            start: moment().format('YYYY-MM-DD'),
            end:  moment().format('YYYY-MM-DD')
          }

          this.selectCategorias = [{
            id: 0,
            name: 'todas as categorias'
          }]

          this.selectedConta = [{
            id: 0,
            name: 'todas as contas'
          }],

          this.selectedCostCenter = [{
            id: 0,
            name: 'todas os centros de custos'
          }],

          this.considerarPagos = false


          _addlFilter['Records:dtEmission_>='] =  this.rangeDate.start + ' 00:00:00'
          _addlFilter['Records:dtEmission_<='] =  this.rangeDate.end + ' 23:59:59',
          this.getLancamentosPorCategorias({ _addlFilter })
              .finally(() => this.loadingRelatorio = false)
        }
      },

      getPercentage(totalLinha, totalGeral) {
        return (totalLinha / totalGeral) * 100
      },

       setTipoVisualizacao(tipoVisualizacao) {
        this.selectedTipoVisualizacao = tipoVisualizacao


        this.filtrar(this.rangeDate)
      },

      setCategories(category) {
        if (!category.id) {
          this.selectCategorias = {
            name: category.name,
            id: category.id
          }
        } else {
          this.selectCategorias = this.selectCategorias.filter(item => item.id !== 0)

          const filterSelected = this.selectCategorias.find(item => item.id === category.id)

          if (filterSelected) {
            const selectedCategoriesFiltered = this.selectCategorias.filter(item => item.id !== category.id)
            if (selectedCategoriesFiltered) {
              this.selectCategorias = this.selectCategorias.filter(item => item.id !== category.id)
            } else {
              this.selectCategorias = [{
                name: category.name,
                id: category.id
              }]
            }
          } else {
            this.selectCategorias.push({
              name: category.name,
              id: category.id
            })  
          }

          // this.selectCategorias = {
          //   name: `a categoria ${category.name}`,
          //   id: category.id
          // }
        }

        this.filtrar(this.rangeDate)
      },

      setConta(contas) {
        if (!contas.id) {
          this.selectedConta = [{
            name: contas.name,
            id: contas.id
          }]
        } else {
           this.selectedConta = this.selectedConta.filter(item => item.id !== 0)

          const filterSelected = this.selectedConta.find(item => item.id === contas.id)

          if (filterSelected) {
            const selectedContasFiltered = this.selectedConta.filter(item => item.id !== contas.id)
            if (selectedContasFiltered) {
              this.selectedConta = this.selectedConta.filter(item => item.id !== contas.id)
            } else {
              this.selectedConta = [{
                name: contas.name,
                id: contas.id
              }]
            }
          } else {
            this.selectedConta.push({
              name: contas.name,
              id: contas.id
            })  
          }
          // this.selectedConta = {
          //   name: `a conta ${contas.name}`,
          //   id: contas.id
          // }
        }


        this.filtrar(this.rangeDate)
      },

      setCostCenter(costCenter) {
        if (!costCenter.id) {
          this.selectedCostCenter = [{
            name: costCenter.name,
            id: costCenter.id
          }]
        } else {
          this.selectedCostCenter = this.selectedCostCenter.filter(item => item.id !== 0)

          const filterSelected = this.selectedCostCenter.find(item => item.id === costCenter.id)

          if (filterSelected) {
            const selectedCostCenterFiltered = this.selectedCostCenter.filter(item => item.id !== costCenter.id)
            if (selectedCostCenterFiltered) {
              this.selectedCostCenter = this.selectedCostCenter.filter(item => item.id !== costCenter.id)
            } else {
              this.selectedCostCenter = [{
                name: costCenter.name,
                id: costCenter.id
              }]
            }
          } else {
            this.selectedCostCenter.push({
              name: costCenter.name,
              id: costCenter.id
            })  
          }
        }


        this.filtrar(this.rangeDate)
      }
    },

    computed: {
      ...mapGetters('relatorioCategorias', ['lancamentoPorCategorias', 'lancamentoCosting', 'graficoDonutD', 'graficoDonutR', 'graficoColuna']),
      ...mapGetters('organizzeFinanceiroCategorias', ['categories']),
      ...mapGetters('organizzeFinanceiroLancamentos', ['accountList']),
      ...mapGetters('organizzeFinanceiroLancamentos', ['accountList']),
      ...mapGetters('organizzeCentroCustos', ['costCentersMapped']),
      formatCategories () {
        const categories = cloneDeep(this.categories)

        const categoriesPai = categories.filter(item => !item.category)

        categoriesPai.forEach(item => {
          item.children = categories.filter(sitem => sitem.categoryId === item.id)
        })

        const categoriesGrouped = groupBy(categoriesPai, item => item.categoryGroup)

        return categoriesGrouped
      }
    },
  }

</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';
  .container {
    position: relative;

    &-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 99;
      background-color: rgba(255, 255, 255, .6);
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .wrapper {
    max-width: 1250px;
    background-color: #FFF;
    margin: 0 auto;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
    padding: 10px !important;

    &-titulo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 15px;

      h1, div {
        width: 200px;
      }
    }

    &-subtitulo {
      display: flex;
      position: relative;
      align-items: flex-end;
      position: relative;
      width: 100%;
      margin-bottom: 15px;
      &-total{
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 0px;
        padding: 10px;
        max-width: 20vw;
        background:#008acf;
        text-align: center;
        font-weight: bold;
        color: white
      }
      .v-input{
        margin-top: 0;
        width: 100%;
      }
      h2.titulo {
        color: #4a5275;
        text-transform: none;
        font-size: 20px;
      }

      p {
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 14px;
      }
    }
  }

  .box-table {
  }
</style>